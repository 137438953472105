:root {
    --toastify-color-progress-dark: #88fc86;
}

body {
  background-image: url("/src/assets/HUD/TitleScreen-export.png");
}
#notificationList {
    position: fixed;
    top: 10px;
    right: 150px;
    width: 350px;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
.Toastify__toast-theme--dark {
    background-color: #00000077 !important;
    box-shadow: 1px 5px 10px #000!important;
    font-weight: bold!important;
  white-space: nowrap!important;
  pointer-events: none!important;
}

.Toastify__toast-container--top-right {
  top: 3em !important;
  pointer-events: none !important;

}

  .Toastify__progress-bar-theme--dark {
      background-color: #97FF78 !important;
      color: #88fc86;
}

  .notification .notification-img {
    width: 50px;
    height: 50px;
    float: left;
    /* padding-top: 16px; */
    /* padding-bottom: 16px; */
    box-sizing: border-box;
    background-color: #111;
    color: #fff;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
  
  .notification .notification-text {
    color: #fff;
    font: 1.1em/1em "VT323";
    padding: 16px;
    overflow: hidden;
    white-space: nowrap;
    opacity: 0;
    -webkit-animation: textin 0.5s 0.5s, textstay 3s 1s forwards;
    animation: textin 0.5s 0.5s, textstay 3s 1s forwards;
  }
  
  .notification-ico {
    margin-top: 10px;
    image-rendering: pixelated;
  
    height: 30px;
    width: 30px;
  }
  
  .notification {
    visibility: visible;
    max-width: 50px;
    height: 50px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    z-index: 1;
    font-size: 17px;
    margin-bottom: 10px;
    white-space: nowrap;
    -webkit-animation: fadein 0.5s, expand 0.5s 0.5s, stay 3s 1s forwards,
      /*shrink 0.5s 2s,*/ fadeout 0.5s 4.5s forwards, reducesize 0.5s 5s forwards;
    animation: fadein 0.5s, expand 0.5s 0.5s, stay 3s 1s forwards,
      /*shrink 0.5s 4s,*/ fadeout 0.5s 4.5s forwards, reducesize 0.5s 5s forwards;
  }
  
  @-webkit-keyframes reducesize {
    from {
      height: 50px;
      margin-bottom: 10px;
    }
    to {
      height: 0px;
      margin-bottom: 0px;
    }
  }
  
  @keyframes reducesize {
    from {
      height: 50px;
      margin-bottom: 10px;
    }
    to {
      height: 0px;
      margin-bottom: 0px;
    }
  }
  
  @-webkit-keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }
  
  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }
  
  @-webkit-keyframes expand {
    from {
      min-width: 50px;
    }
    to {
      min-width: 350px;
    }
  }
  
  @keyframes expand {
    from {
      min-width: 50px;
    }
    to {
      min-width: 350px;
    }
  }
  @-webkit-keyframes stay {
    from {
      min-width: 350px;
    }
    to {
      min-width: 350px;
    }
  }
  
  @keyframes stay {
    from {
      min-width: 350px;
    }
    to {
      min-width: 350px;
    }
  }
  @-webkit-keyframes shrink {
    from {
      min-width: 350px;
    }
    to {
      min-width: 50px;
    }
  }
  
  @keyframes shrink {
    from {
      min-width: 350px;
    }
    to {
      min-width: 50px;
    }
  }
  
  @-webkit-keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  @-webkit-keyframes textstay {
    from {
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes textin {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes textstay {
    from {
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }
  




.game-container {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

#inventory {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 25%;
    max-width: 150px;
    height: auto;
    justify-content: space-around;
    display:flex;
}

#inventory img {
    width:150%;
}

.hud {
      pointer-events: none;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 1px 5px 10px #000;
    margin: 10px;
    image-rendering: pixelated;
}


#toggle-notifications {
  pointer-events:all;
  position: absolute;
  width: 312px;
  top: 0px;
  right: 1em;
  color: white;
  box-shadow: none;

  border: none;
  background-color: #00000077 !important;
  box-shadow: 1px 5px 10px #000!important;
  font-weight: bold!important;
  white-space: nowrap!important;
}

#toggle-notifications:active {
  top: 2px;
}

@media (max-width:900px) {
  #toggle-notifications {
    visibility: hidden;
  }
}


#gif_ui {
    position: absolute;
    bottom: 0;
    left: 0px;
}

#co2 {
    position: absolute;
    bottom: 0px;
    width: 30px;
    height: 200px;
    right: 0px;
}

 #relativeCO2 {
    position: relative;
    width: 100%;
    height: 100%;
}

#interieur_CO2 {
    padding-bottom: 2px;
}

#co2 img {
    z-index: 3;
    position: absolute;
    width:60px;
    height: 100%;
    bottom: 0;
    left: -15px;
}
