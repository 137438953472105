.end-text {
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	position: absolute;
	display: flex;
	align-content: center;
	align-items: center;
	text-align: center;
	justify-content: center;
	font-size: 10em;
	display:flex;
	flex-direction:column;
}